import { IDropdownOption } from '@fluentui/react';
import { TenantManager } from '@samc/single-spa-client-configuration';

/**
 * NOTE: this deprecated function now also sets the URL parameter for the tenant.
 * @deprecated use {@link TenantManager} instead.
 */
export function setTenantCookie(value: string): void {
    TenantManager.setTenantId(value);
}

/**
 * NOTE: this deprecated function now also gets the tenant from the URL parameter.
 * @deprecated use {@link TenantManager} instead.
 */
export function getTenantIdFromCookie(): string {
    return TenantManager.getTenantId() || '';
}

export const getFirstOptionKey = (options: IDropdownOption[]): string | null => {
    return options && options.length !== 0 && options[0] && options[0].key ? String(options[0].key) : null;
};

/**
 * @deprecated use {@link TenantManager} instead.
 */
export function getTenantIdFromURL(search: string, options: IDropdownOption[]): string | null {
    const tenantParams = new URLSearchParams(search)?.get('tenant');
    const validTenant = options.find((x) => String(x.key).toLowerCase() === String(tenantParams).toLowerCase());
    return tenantParams && validTenant !== undefined && validTenant?.key ? String(validTenant?.key) : null;
}

import * as GenericApiContext from '@samc/react-ui-request/lib/contexts/ApiContext/ApiContext';

export const REQUIREDURIS = ['TenantApi', 'AuthorizationApi'];

export interface ApiContextType extends GenericApiContext.ApiContextType {
    TenantApi: string;
    AuthorizationApi: string;
}

export const ApiContext = GenericApiContext.ApiContext as unknown as React.Context<ApiContextType>;
export const ApiProvider = GenericApiContext.ApiProvider as unknown as React.Provider<ApiContextType>;
export const ApiConsumer = GenericApiContext.ApiConsumer as unknown as React.Consumer<ApiContextType>;

export const useApiContext = (suppressErrors?: boolean): ApiContextType => {
    GenericApiContext.useAssertApiKeys(REQUIREDURIS, suppressErrors);

    return GenericApiContext.useApiContext() as unknown as ApiContextType;
};

import * as React from 'react';
import { useRequestBase, RequestResult, RequestError } from '@samc/react-ui-request';
import { getTenants } from '../Requests';
import { ApiContext, ApiContextType } from '../ApiContext';
import { Tenant } from '../../viewmodels/Tenant';

export const TENANTS_KEY = 'tenants';

export const useTenants = (): RequestResult<Tenant[]> => {
    const api = React.useContext<ApiContextType>(ApiContext);

    // use a very long stale time, tenants should not change while a user is logged in
    const staleTime = 1000 * 60 * 60 * 24;

    const query = async (url: string): Promise<Tenant[]> => {
        return getTenants(url, api.requestInit);
    };

    const response = useRequestBase<Tenant[], RequestError, ApiContextType, Parameters<typeof getTenants>>({
        key: TENANTS_KEY,
        query,
        requestContext: ApiContext,
        params: [api.TenantApi],
        options: {
            staleTime,
        },
    });

    return response;
};

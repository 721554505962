import * as React from 'react';
import { BehaviorSubject } from 'rxjs';
import { Tenant } from '../../viewmodels/Tenant';

// Subject that can mutate over time
const tenantSubject = new BehaviorSubject<Tenant | null>(null);

// Observable for subscribing to subject change updates
export const tenant$ = tenantSubject.asObservable();

/**
 *
 * Updates the tenant data subject by triggering the observer next() method
 * Must have been subscribed to the tenant$ observable to listen to changes.
 * @param tenant - receives an object or a null assignment
 */
export const emitTenant = (tenant: Tenant | null): void => tenantSubject.next(tenant);

/**
 * Custom hook which subscribes to the tenant observable and makes it available as a
 * stateful value
 * @returns tenantState reflecting the tenant$ observable
 */
export const useTenantState = (): Tenant | null => {
    const [tenantState, setTenantState] = React.useState<Tenant | null>(null);

    React.useEffect(() => {
        const subscriber = tenant$.subscribe((state) => {
            setTenantState(state as Tenant);
        });
        return (): void => subscriber.unsubscribe();
    }, []);

    return tenantState;
};

export default useTenantState;

import { mergeHeaders } from '@samc/react-ui-request';
import { Tenant } from '../viewmodels/Tenant';

const fetchInternal = async (input: RequestInfo, init?: RequestInit | undefined): Promise<Response> => {
    const response = await fetch(input, init);

    if (response.status >= 400 && response.status < 600) {
        const body = await response.json();
        if (body.developerMessage) {
            throw new Error(`${response.statusText}: ${body.developerMessage.message}`);
        }
        if (body.messages && body.messages.length > 0) {
            throw new Error(`${response.statusText}: ${body.messages[0]}`);
        }
    }

    return response;
};

export const getTenants = async (url: string, requestInit?: RequestInit): Promise<Tenant[]> => {
    const response = await fetchInternal(`${url}/api/v1/Tenant`, {
        method: 'GET',
        headers: requestInit?.headers,
    });

    return response.json();
};

export const refreshUserInfo = (url: string, tenant: string, requestInit?: RequestInit): void => {
    fetchInternal(`${url}/api/v2/jwt/refreshUserInfo`, {
        method: 'POST',
        headers: mergeHeaders({
            ...requestInit?.headers,
            ...{ 'X-Tenant': tenant },
        }),
    });
};

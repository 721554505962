// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../.yarn/__virtual__/css-loader-virtual-c32b5e6d13/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../.yarn/__virtual__/css-loader-virtual-c32b5e6d13/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#\\@samc\\/single-spa-tenant-selector .tenant-selector {
    width: 180px;
    height: 42px;
    font-family: Arial;
    font-size: 12px;
    color: #707070;
    text-decoration: none solid #707070;
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 2px;
    padding-left: 6px;
    padding-top: 5px;
    border-right: solid 1px #ededed;
    box-shadow: rgb(0 0 0 / 20%) 0px 1px 2px;
}

#\\@samc\\/single-spa-tenant-selector .tenant-selector .ms-Dropdown-title {
    border-style: none;
    color: white;
    outline: none;
}

#\\@samc\\/single-spa-tenant-selector .tenant-selector .ms-Dropdown-title:hover {
    border-style: none;
    color: white;
}
`, "",{"version":3,"sources":["webpack://./src/pages/TenantSelector/TenantSelector.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,mCAAmC;IACnC,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;IAChB,+BAA+B;IAC/B,wCAAwC;AAC5C;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".tenant-selector {\n    width: 180px;\n    height: 42px;\n    font-family: Arial;\n    font-size: 12px;\n    color: #707070;\n    text-decoration: none solid #707070;\n    margin-left: 0px;\n    margin-right: 0px;\n    padding-right: 2px;\n    padding-left: 6px;\n    padding-top: 5px;\n    border-right: solid 1px #ededed;\n    box-shadow: rgb(0 0 0 / 20%) 0px 1px 2px;\n}\n\n.tenant-selector .ms-Dropdown-title {\n    border-style: none;\n    color: white;\n    outline: none;\n}\n\n.tenant-selector .ms-Dropdown-title:hover {\n    border-style: none;\n    color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import * as React from 'react';
import { IDropdownOption, Dropdown, IDropdownStyles, Icon, Spinner } from '@fluentui/react';
import { useThemeContext } from '@samc/react-ui-theme';
import { TenantManager, useTenantId } from '@samc/single-spa-client-configuration';
import { useTenants } from '../../api/hooks/useTenants';
import './TenantSelector.css';
import { Tenant } from '../../viewmodels/Tenant';
import { emitTenant } from '../../api/hooks/useTenantState';
import { getFirstOptionKey } from './TenantSelector.Utilities';
import { refreshUserInfo } from '../../api/Requests';
import { ApiContext, ApiContextType } from '../../api/ApiContext';

interface InnerTenantSelectorProps {
    allTenants: Tenant[];
}

const InnerTenantSelector: React.FC<InnerTenantSelectorProps> = (props) => {
    const { allTenants } = props;

    const selectedKey = useTenantId();

    const theme = useThemeContext();
    const api = React.useContext<ApiContextType>(ApiContext);

    const selectedTenant = React.useMemo(
        () => allTenants.find((t) => t.name === selectedKey || t.identifier === selectedKey),
        [allTenants, selectedKey],
    );

    // Build our options
    const options = React.useMemo(
        () =>
            allTenants
                .filter((t) => t.isActive)
                .map(
                    (item: Tenant): IDropdownOption => ({
                        key: String(item.name),
                        text: String(item.description),
                    }),
                ),
        [allTenants],
    );

    // change tenant based on user selection
    const handleTenantChange = React.useCallback((_event: unknown, newItem?: IDropdownOption): void => {
        if (newItem && newItem.key !== 0) {
            const key = String(newItem.key);
            TenantManager.setTenantId(key);
        }
    }, []);

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: {
            background: `${theme.SupplementaryColors.darkblue.toString()}`,
            color: `${theme.TextColors.light.toString()}`,
        },
        title: {
            background: `${theme.SupplementaryColors.darkblue.toString()}`,
            color: `${theme.TextColors.light.toString()}`,
        },
    };

    const onRenderCaretDown = (): JSX.Element => {
        return <Icon style={{ fontSize: '9px' }} iconName="CaretDownSolid8" />;
    };

    function refreshUser(tenant: Tenant): void {
        refreshUserInfo(api.AuthorizationApi, tenant.name, api.requestInit);
    }

    // handle initial tenant selection
    const hasSelectedTenant = selectedTenant !== undefined;
    React.useEffect(() => {
        if (!hasSelectedTenant) TenantManager.setTenantId(getFirstOptionKey(options));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Update the tenant observable based on the selection
    React.useEffect(() => {
        if (selectedTenant) {
            emitTenant(selectedTenant);
            refreshUser(selectedTenant);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTenant]);

    return (
        <div className="tenant-selector" style={{ background: `${theme.SupplementaryColors.darkblue.toString()}` }}>
            <Dropdown
                styles={dropdownStyles}
                options={options}
                onChange={handleTenantChange}
                selectedKey={selectedKey}
                onRenderCaretDown={onRenderCaretDown}
            />
        </div>
    );
};

export const TenantSelector: React.FC = () => {
    const { data: tenants, isLoading: areTenantsLoading } = useTenants();

    if (areTenantsLoading) return <Spinner label="Loading tenants..." labelPosition="right" />;
    if (!tenants) return <div>Failed to load tenants</div>;

    return <InnerTenantSelector allTenants={tenants} />;
};

export default TenantSelector;

import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthorizationWrapper, useUserAuthenticationState } from '@samc/single-spa-authentication';
import { Customizer, LayerHost } from '@fluentui/react';
import { HashRouter } from 'react-router-dom';
import { useClientConfiguration } from '@samc/single-spa-client-configuration';
import { ApiProvider } from './api/ApiContext';
import { TenantSelector } from './pages/TenantSelector/TenantSelector';
import { getTenantIdFromCookie } from './pages/TenantSelector/TenantSelector.Utilities';

const queryClient = new QueryClient();

const Root = (): React.ReactElement => {
    return <TenantSelector />;
};

const Wrapper = (): React.ReactElement => {
    const authState = useUserAuthenticationState();
    const userIsAuthenticated = React.useMemo(() => !!authState?.isAuthenticated, [authState]);
    const token = authState?.accessToken?.accessToken;
    const tenantParams =
        new URLSearchParams(window.location.search)?.get('tenant') ??
        new URLSearchParams(window.location.href)?.get('tenant');

    const { result: clientConfiguration } = useClientConfiguration();

    if (!userIsAuthenticated || clientConfiguration === undefined) return <></>;

    const headers = {
        Authorization: `Bearer ${token}`,
        'X-Tenant': tenantParams || getTenantIdFromCookie(),
    };

    return (
        <AuthorizationWrapper
            config={{
                ...clientConfiguration,
                defaultHeaders: Object.entries(headers).map(([k, v]) => ({ name: k, value: v })),
            }}
            jwt={token}
        >
            <Customizer scopedSettings={{ Layer: { hostId: 'single-spa-tenant-selector-layerhost' } }}>
                <LayerHost
                    id="single-spa-tenant-selector-layerhost"
                    style={{
                        position: 'absolute',
                        zIndex: 2147483647, // max
                        top: 0,
                        pointerEvents: 'none',
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }}
                />
                <QueryClientProvider client={queryClient}>
                    <ApiProvider
                        value={{
                            requestInit: {
                                headers,
                            },
                            TenantApi: '/_apis/tenant',
                            AuthorizationApi: '/_apis/authorization',
                            ShowMessage: (_msg: string): void => undefined,
                        }}
                    >
                        <HashRouter>
                            <Root />
                        </HashRouter>
                    </ApiProvider>
                </QueryClientProvider>
            </Customizer>
        </AuthorizationWrapper>
    );
};

export default Wrapper;
